
$(function(){ 
var mql = window.matchMedia('screen and (min-width: 768px)');     
//::::::::::::::    
// IE11
//::::::::::::::    
if(navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./)) {
  $('body').on("mousewheel", function (){
    // デフォルトの設定値をOFFに切り替える
    event.preventDefault();
    var wd = event.wheelDelta;
    var csp = window.pageYOffset;
    window.scrollTo(0, csp - wd);
  });
}
var printCheck = $('.js_print').css('display') == 'block' //印刷状態をチェック    
if(!printCheck){  
    var fadeoffset = $(window).innerHeight() * 0.25;   
    var controller = new ScrollMagic.Controller();
    var fadeBottom = $('.js_fadein,.parts-row>div,.option-parts-item');
    if (!mql.matches){var fadeBottom = $('.js_fadein,.parts-row>div,.option-parts-item,.sp-detail-arrange .gallery_item');}else{}
    for(var i = 0; i < fadeBottom.length;i++){
        var tagfadebtm = fadeBottom[i];
        var tween3;
        tween3 = TweenMax.fromTo(tagfadebtm, 0.65, {
                y: "30px",
                opacity: 0
            }, // left 0、opacity 0の状態を指定
            {
                y: 0,
                opacity: 1
            } // left 100px、opacity 1まで変化させる
        );


        var fadebottom = new ScrollMagic.Scene({
            triggerElement:tagfadebtm,
            triggerHook: 'onEnter',
            offset:fadeoffset,
            reverse:false  
        })        
        .setTween(tween3)  
        .addTo(controller)
        //.addIndicators({name:"fadein"});
    }
  
    //画像 スワイプしながらin    
    var SwipeLeft = $('.js_swipe-l');
    for(var i = 0; i < SwipeLeft.length;i++){
        var tag = SwipeLeft[i];

        var tween1;
        tween1 = new TweenMax.to(tag,2,{className :'+=js_isshow'});    
        new ScrollMagic.Scene({      
            triggerElement:tag,
            triggerHook: 'onEnter',
            offset:fadeoffset,
            reverse:false  
        })
            .setTween(tween1)  
            .addTo(controller); 
    }

    //テキストエリアのフェードイン    
    var fadeTxt = $('.js_fadetxt');   
    for(var i = 0; i < fadeTxt.length;i++){
        var tagfade = fadeTxt[i];
        var Elm = fadeTxt.eq(i).children(); 

        var tween2;
        tween2 = new TimelineMax()
            .fromTo(tagfade,0.5, {x:"0" , background:"transparent"},{delay: 0.3,x:0 , background:"transparent"})
            .staggerFromTo(Elm,0.5,{y:"10px"},{y:0,opacity:1},
                           //↑まではtoと同じ記法で、↓にアニメーションの時間差を指定します。
                           0.15)

        var fadeanime = new ScrollMagic.Scene({      
            triggerElement:tagfade,
            triggerHook: 'onEnter',
            //offset:100,
            reverse:false  
        })
        .setTween(tween2)  
        .addTo(controller); 
    }    
    
}
    
});